<template>
  <div class="zy-layout">
    <slot name="dialog"></slot>
    <zy-column-render
      v-for="(node, index) in $slots.default"
      :key="index"
      :node="node"
      :width="columns[index]"
    >
    </zy-column-render>
  </div>
</template>

<script>
import Column from "./column.vue"
export default {
  name: "ZyLayout",
  components: {
    ZyColumnRender: {
      functional: true,
      render(h, ctx) {
        const { node, width } = ctx.props
        //字组件参数
        const componentOptions = node.componentOptions || {}
        let propsData = componentOptions.propsData || {}
        const body_width = propsData.width > 0 ? propsData.width : width
        const body = h(
          "div",
          {
            style: {
              width: `${body_width}px`,
            },
            class: [`page-animation-${propsData.animation}`],
          },
          [node]
        )
        return body
      },
    },
    Column,
  },
  props: {
    // 分两列的时候要设置小块在左边还是右边
    direction: {
      type: String,
      default: "left",
    },
  },
  computed: {
    columns() {
      const number = this.$slots.default.length
      if (number == 1) return [1920]
      if (number == 3) return [534, 752, 534]
      if (this.direction == "left") return [534, 1306]
      return [1306, 534]
    },
  },
}
</script>

<style lang="less" scoped>
@import url("../../style/animation.less");
.zy-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  // background: url("../../assets/images/img_layout_bg.png");
  // background-size: 100% 100%;
  box-sizing: border-box;
  padding: 0 20px;
}
</style>
